<template>
  <div style="position: relative">
    <b-form-input
      type="text"
      v-model="keyword"
      autocomplete="false"
      @input="updateValue"
      :placeholder="placeholder"
      @keyup.native.esc="esc"
      @blur.native="blur"
      @keydown.native.enter="enter"
      @keydown.native.down="down"
      @keydown.native.up="up"
    >
    </b-form-input>
    <b-list-group v-show="open">
      <b-list-group-item
        v-for="(suggestion, index) in matches"
        :key="index"
        v-bind:class="{ active: isActive(index) }"
        @mousedown="suggestionClick(index)"
        href="#"
        >{{
          suggestion.nome_completo ? suggestion.nome_completo : suggestion.nome
        }}</b-list-group-item
      >
    </b-list-group>
  </div>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Array,
      required: true,
    },
    row_index: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    field: {
      type: String,
      default: "nome",
    },
  },
  data() {
    return {
      open: false,
      current: 0,
      id: 0,
      keyword: "",
    };
  },
  computed: {
    // Filtering the suggestion based on the input
    matches() {
      const re = new RegExp(this.keyword, "i");
      return this.suggestions.filter((obj) => obj[this.field].match(re));
    },
    check_valid: function () {
      if (this.id > 0) {
        for (var index in this.suggestions) {
          var item = this.suggestions[index];
          if (this.id == item.id) {
            if (item[this.field] == this.keyword) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  methods: {
    updateValue(value) {
      if (this.check_valid) {
        return;
      }
      this.open = true;
      this.current = 0;
      this.id = 0;
      this.$emit("change", value, this.row_index);
    },
    esc() {
      this.open = false;
    },
    blur() {
      this.open = false;
    },
    // When enter pressed on the input
    enter() {
      this.id = this.matches[this.current].id;
      this.$emit("select", this.id, this.row_index);
      this.open = false;
      this.keyword = this.matches[this.current][this.field];
    },
    // When up pressed while suggestions are open
    up() {
      if (this.current > 0) {
        this.current--;
      }
    },
    // When up pressed while suggestions are open
    down() {
      if (this.current < this.matches.length - 1) {
        this.current++;
      }
    },
    // For highlighting element
    isActive(index) {
      return index === this.current;
    },
    // When one of the suggestion is clicked
    suggestionClick(index) {
      this.id = this.matches[index].id;
      this.$emit("select", this.id, this.row_index);
      this.open = false;
      this.keyword = this.matches[index][this.field];
    },
    setNome(id, nome) {
      this.id = id;
      this.$emit("select", id);
      this.open = false;
      this.keyword = nome;
    },
  },
};
</script>
